import React, { useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../../../components/Layout/Layout"
import Article from "../../../components/Article/Article"
import CallToAction from "../../../components/Article/CallToAction"
import Breadcrumbs from "../../../components/Breadcrumbs/Breadcrumbs"
import i18n from "i18next"
import A from "../../../components/FastLink/A"

const SeoArticle = ({ data }) => {
  useEffect(() => {
    i18n.changeLanguage("pl")
  }, [])
  return (
    <Layout
      seo={{
        title: "Czym są meta dane i czy ich treść ma znaczenie w kontekście SEO?",
        description:
          "Dowiedz się, czym są i czym się charakteryzują: Meta Title, Meta Description i Meta Keywords oraz poznaj ich znaczenie podczas działań związanych z SEO.",
        schema: {
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          itemListElement: [
            {
              "@type": "ListItem",
              position: 1,
              item: {
                "@id": "/",
                name: "Główna",
              },
            },
            {
              "@type": "ListItem",
              position: 2,
              item: {
                "@id": "/baza-wiedzy/artykuly/",
                name: "Baza wiedzy",
              },
            },
            {
              "@type": "ListItem",
              position: 3,
              item: {
                "@id": "/baza-wiedzy/artykul/czym-sa-meta-title-meta-description-i-meta-keywords/",
                name: "Czym są meta dane i czy ich treść ma znaczenie w kontekście SEO?",
              },
            },
          ],
        },
      }}
    >
      <Breadcrumbs
        path={[
          { url: "/", text: "Główna" },
          {
            url: "/baza-wiedzy/artykuly/",
            text: "Baza wiedzy / Artykuły",
          },
          {
            url: "/baza-wiedzy/artykul/czym-sa-meta-title-meta-description-i-meta-keywords",
            text: "Czym są Meta Title, Meta Description i Meta Keywords?",
          },
        ]}
      />
      <Article
        img={
          <img
            src={data.image1.childImageSharp.fluid.src}
            alt={"Czym są Meta Title, Meta Description i Meta Keywords?"}
          />
        }
      >
        <h1>Czym są meta title, meta description i meta keywords?</h1>
        <p>
          <strong>
            Obecnie prowadzenie bloga czy strony WWW wymaga już nie tylko ciekawych treści, ale także optymalizacji pod
            kątem wyszukiwarek internetowych. Kluczowe znaczenie w opisywaniu zawartości danej strony WWW mają tzw. meta
            dane. Choć służą przede wszystkim robotom indeksującym, to mają też duży wpływ na doświadczenie użytkownika
            z witryną. Czym jest tag meta title i jak napisać dobry? Jak przygotować meta description i ile znaków musi
            mieć? Czy strategia Meta Keywords ma znaczenie dla SEO? Na te i inne pytania odpowiadamy poniżej. Zapraszamy
            do lektury!
          </strong>
        </p>
        <h3>Czym jest znacznik meta title i jak działa?</h3>
        <p>
          Czy wiesz, jaki jest najważniejszy znacznik HTML w witrynie? Z pewnością jest to jedno z najpopularniejszych
          stwierdzeń dotyczących meta title. Nie powinno Cię to dziwić skoro należy on do kluczowych elementów strony
          internetowej, który bezpośrednio wpływa na jej pozycjonowanie w wyszukiwarce.
        </p>
        <h3>Gdzie wyświetla się meta tytuł?</h3>
        <p>
          Podczas tworzenia pamiętaj, że tekst ten wyświetla się jako tytuł strony w wynikach wyszukiwania, a także na
          pasku tytułowym przeglądarki. Informuje użytkowników oraz meta robots o tematyce danego serwisu.
        </p>
        <p>
          Dowiedz się,{" "}
          <a
            href="https://blog.wenet.pl/seo-pozycjonowanie/czym-jest-audyt-seo-dlaczego-jest-potrzebny-wlascicielom-stron-www"
            target="_blank"
            rel="nofollow"
          >
            co to jest audyt SEO
          </a>{" "}
          strony internetowej. Zdobądź niezbędną wiedzę!
        </p>
        <p>Ponadto może wyświetlać się również w mediach społecznościowych (po udostępnieniu linku).</p>
        <p>
          Co ciekawe, tag tytułu nie jest widoczny bezpośrednio wśród treści na stronie internetowej. Znaleźć go można
          dopiero w kodzie źródłowym (HTML).
        </p>
        <p>Przykład odpowiedniego meta title na karcie przeglądarki:</p>
        <figure>
          <img src={data.image2.childImageSharp.fluid.src} alt="Przykładowy Meta Title na karcie przeglądarki" />
          <figcaption>
            Źródło: <A to="https://www.panoramafirm.pl" text={"panoramafirm.pl"} />
          </figcaption>
        </figure>
        <p>Przykładowy tag meta title na stronie wyszukiwania Google:</p>
        <figure>
          <img
            src={data.image3.childImageSharp.fluid.src}
            alt="Przykładowy Meta Title w wynikach wyszukiwania Google"
          />
          <figcaption>
            Źródło: <A to="https://google.pl" text={"google.pl"} />
          </figcaption>
        </figure>
        <h3>Budowa znacznika: jaka powinna być optymalna długość meta title?</h3>
        <p>
          Jednym z najważniejszych aspektów, o jakie musisz zadbać jest długość meta title. Znacznik powinien mieć
          maksymalnie około 60-70 znaków, aby był w całości widoczny w wynikach wyszukiwania i sprzyjał optymalizacji
          SEO. Kluczowe słowa należy umieścić na początku tytułu, aby wzmocnić ich znaczenie. Ważne jest również, aby
          unikać wykorzystywania zbyt ogólnych fraz, które nie przynoszą wartości ani dla użytkowników, ani dla
          wyszukiwarek.
        </p>
        <p>Struktura prezentuje się w następujący sposób: &lt;title&gt;Tytuł strony&lt; /title&gt;</p>
        <h3>Tag meta title a SEO</h3>
        <p>
          Unikatowy meta title ma na celu poinformować użytkownika o zawartości, jaką znajdzie pod konkretnym adresem
          WWW. Jego treść powinna być tak skonstruowana, żeby zaciekawić i zachęcić czytelnika do zapoznania się z
          zawartością strony – zupełnie jak dobry tytuł w prasie drukowanej.
        </p>
        <p>
          Zleć <A to="https://audytseo.wenet.pl/" text={"audyt strony WWW"} /> i popraw jej widoczność w wyszukiwarce.
        </p>
        <p>
          Ponadto odpowiednio skonstruowany meta title – co szczególnie ważne w kontekście e-marketingu – ma silny wpływ
          na SEO, tj. stanowi jeden z czynników rankingowych Google. Jest on bowiem jednym z pierwszych elementów
          analizowanych przez wyszukiwarki podczas indeksowania witryny. Dobre tytuły stron przyczyniają się do lepszego
          pozycjonowania, a tym samym zwiększają widoczność w wynikach wyszukiwania.
        </p>
        <h3>Jak napisać dobry tytuł i podkręcić meta title?</h3>
        <p>Aby meta tytuł spełniał swoje funkcje i pozytywnie wpływał na optymalizację SEO, powinien:</p>
        <ul>
          <li>
            <p>
              być odpowiedniej długości, czyli od 50 do 70 znaków (choć Google nie podaje konkretnych wytycznych, to
              długość znacznika meta tytułu ogranicza liczba pikseli - przyjmuje się, że nie powinna przekraczać ona
              580-600),
            </p>
          </li>
          <li>
            <p>zawierać słowa kluczowe, związane z zawartością strony, artykułu, wpisu itp.,</p>
          </li>
          <li>
            <p>różnić się od nagłówka opublikowanego w witrynie,</p>
          </li>
          <li>
            <p>być atrakcyjny, aby zaciekawić odbiorcę i zachęcić go do kliknięcia linku.</p>
          </li>
        </ul>
        <p>
          Zależy Ci na optymalizacji meta title? Pamiętaj, aby unikać duplikatów i staraj się robić wszystko, co pomoże
          zwrócić uwagę użytkowników. W tym celu stosuj znaki specjalne, cyfry czy wielkie litery, które mogą pomóc
          wyróżnić znacznik w wynikach wyszukiwania i tym samym wzmocnić pozycjonowanie strony internetowej.
        </p>
        <CallToAction
          to={"/"}
          text={"Wykonaj darmowy audyt strony i popraw jej widoczność w wyszukiwarce. Wykonaj teraz >>"}
        />
        <h2>Co to znaczy Meta Description i dlaczego jest ważny?</h2>
        <p>
          Znasz już mechanizm działania meta title. Czas dowiedzieć się, jak przygotować meta description, czyli opis
          strony internetowej. Widoczny jest w całości lub we fragmencie w wynikach wyszukiwania, a czasami także w
          mediach społecznościowych po udostępnieniu linku (w obu przypadkach opis wyświetla się pod tytułem strony lub
          artykułu).
        </p>
        <p>Jak wygląda meta opis? Oto przykład z wyszukiwarki Google:</p>
        <figure>
          <img src={data.image4.childImageSharp.fluid.src} alt="Przykładowy Meta Description w wyszukiwarce Google" />
          <figcaption>
            Źródło: <A to="https://google.pl" text={"google.pl"} />
          </figcaption>
        </figure>
        <h3>Czy meta opis wpływa na SEO?</h3>
        <p>
          W przeciwieństwie do tagu tytułu nie należy do znaczników SEO i nie jest czynnikiem rankingowym. Ma jednak
          wpływ na CTR, czyli współczynnik klikalności. Dlatego warto zadbać o odpowiednie brzmienie i atrakcyjność
          opisu, ponieważ bez wątpienia oddziałuje on na decyzje użytkownika wyszukiwarki. Jeśli nie przygotujesz go
          samodzielnie, wyszukiwarka zrobi to za Ciebie, używając do tego celu fragmentu treści Twojej strony.
        </p>
        <p>
          Dzięki intrygującemu i wyczerpującemu opisowi masz szansę zwiększyć CTR Twojej witryny. Niektórzy, żeby
          przyciągnąć uwagę internautów, stosują emoij, czyli piktogramy (np. buźki wyrażające emocje), jednak nie
          zawsze jest to forma pasująca do prezentowanych treści.
        </p>
        <p>
          Jak wygląda optymalizacja meta description? O czym należy pamiętać podczas tworzenia? Choć wpływ na
          pozycjonowanie jest w przypadku opisu znikomy, to warto trzymać się długości tekstu, która pozwoli na
          wyświetlenie całego lub znacznej jego części w wynikach wyszukiwania. Szacuje się, że 150-160 znaków to
          optymalna wartość. Opis powinien też zawierać słowo kluczowe. Zadbaj przy jego tworzeniu o unikalność,
          zgodność z zawartością witryny oraz dodanie zachęty do kliknięcia.
        </p>
        <h3>Czym są Meta Keywords?</h3>
        <p>
          To element meta danych, który kiedyś odgrywał istotną rolę w optymalizacji SEO. Najważniejsze słowa kluczowe
          umieszczane były w kodzie źródłowym strony, co miało na celu ułatwienie wyszukiwarkom indeksowania i
          klasyfikowania treści.
        </p>
        <p>
          Jednak z biegiem czasu, roboty wyszukiwarek zaczęły ignorować te meta znaczniki, ze względu na nadużycia ze
          strony twórców stron internetowych, którzy umieszczali nieistotne słowa kluczowe, aby manipulować wynikami
          wyszukiwania.
        </p>
        <p>
          Współcześnie znacznie większe znaczenie mają inne elementy meta danych, takie jak tytuł strony oraz jej opis,
          które nadal są brane pod uwagę przez wyszukiwarki i wpływają na pozycjonowanie strony internetowej.
        </p>
        <h2>Znaczniki meta w optymalizacji SEO - podsumowanie</h2>
        <p>
          Tworzysz meta tagi strony? W tym kontekście często mówi się o trzech głównych elementach: skuteczny meta
          title, atrakcyjny meta opis i meta keywords.
        </p>
        <p>
          Wiesz już, że wpływ meta title na pozycjonowanie jest ogromny, ponieważ znacznik informuje użytkowników i
          roboty indeksujące o tematyce witryny. Meta opis, choć nie jest czynnikiem rankingowym, to może oddziaływać na
          współczynnik klikalności (CTR) i zachęca użytkowników do odwiedzenia strony. Ostatni z meta danych nie odgrywa
          już istotnej roli w SEO ze względu na nadużycia ze strony twórców.
        </p>
        <p>
          Zastosuj się do wskazówek dotyczących tworzenia meta title i meta opisów, takich jak długość, umieszczenie
          fraz i unikalność treści, a wkrótce zobaczysz oczekiwane wyniki.
        </p>
        <p>
          <br className="ProseMirror-trailingBreak" />
        </p>
      </Article>
    </Layout>
  )
}

export const query = graphql`
  {
    image1: file(relativePath: { eq: "img/full/metadane.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1100, jpegQuality: 90) {
          sizes
          src
        }
      }
    }
    image2: file(relativePath: { eq: "img/full/metadane-image1.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1100, jpegQuality: 90) {
          sizes
          src
        }
      }
    }
    image3: file(relativePath: { eq: "img/full/metadane-image2.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1100, jpegQuality: 90) {
          sizes
          src
        }
      }
    }
    image4: file(relativePath: { eq: "img/full/metadane-image3.png" }) {
      childImageSharp {
        fluid(maxWidth: 1100, jpegQuality: 90) {
          sizes
          src
        }
      }
    }
  }
`

export default SeoArticle
